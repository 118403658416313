.login-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-image: url("../Asset/Images/Qr_bg.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.login-form {
  max-width: 400px;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.Login-header {
  -webkit-text-fill-color: white;
}
.Login-textBox {
  background-color: #ffffffe6;
}
.login-box {
  width: 400px;
  padding: 40px;
  border-radius: 10px;
  background-color: #1f658d;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

.login-box-inner {
  padding: 20px;
}

.login-box h2 {
  text-align: center;
  margin-bottom: 20px;
  color: #333333;
}

.p-field {
  margin-bottom: 20px;
}

.p-float-label input {
  width: 100%;
  padding: 12px;
  border: 1px solid #dddddd;
  border-radius: 5px;
  transition: border-color 0.3s ease;
}

.p-float-label input:focus {
  border-color: #62a7a2;
}

.p-float-label label {
  font-size: 14px;
  color: #666666;
}

.error-message {
  color: red;
  margin-bottom: 10px;
  text-align: center;
}

.p-button-raised {
  width: 100%;
  background-color: #62a7a2;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  padding: 12px 0;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.p-button-raised:hover {
  background-color: #163aeb;
}

.button-login {
  -webkit-text-fill-color: white;
  font-weight: bold;
  background-color: #163aeb;
}
.login-logo {
  height: 70px;
  filter: drop-shadow(5px 5px 5px rgb(235, 226, 226))
          drop-shadow(-5px 5px 5px rgba(200, 200, 200, 1))
          drop-shadow(5px -5px 5px rgba(200, 200, 200, 1))
          drop-shadow(-5px -5px 5px rgba(200, 200, 200, 1));
}

