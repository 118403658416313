* {
  /* font-family: "Times New Roman", Times, serif; */
  font-family: "Inter var",sans-serif;
  font-size: small;
}
.container {
  display: block;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  width: 100%;
  background-color: #bdbec5;
  overflow: hidden;
  background-size: 600% 600%;
  animation: gradientAnimation 100s ease infinite;
  background-image: url("../Images/bg_square.png");
  background-size: cover;
}
.container-windows {
  display: block;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  width: 100%;
  background-color: #bdbec5;
  overflow: hidden;
  background-size: 600% 600%;
  animation: gradientAnimation 100s ease infinite;
  background-image: url("../Images/Qr_bg.png");
  background-size: cover;
}
.container-dialer {
  display: block;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  width: 100%;
  background-color: #bdbec5;
  overflow: hidden;
  background-size: 600% 600%;
  animation: gradientAnimation 100s ease infinite;
  background-image: url("../Images/contact.jpg");
  background-size: cover;
}

.container-Encrypt {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-image: url("../Images/bg.jpg");
  animation: gradientAnimation 200s ease infinite;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
.custom-menubar {
  /* background: #025add; */
  background-color: #014fc3;
  border: none;
  box-shadow: 0 -5px 20px #ffffff, 0 10px 20px #cfc6c674;
}
.custom-menubar-windows {
  background-color: #014fc3;
  /* background-color: #014fc3; */
  border: none;
  box-shadow: 0 -5px 20px #ffffff, 0 10px 20px #cfc6c674;
  padding: 20px;
}

h3 {
  -webkit-text-fill-color: rgb(0, 0, 0);
}

p {
  -webkit-text-fill-color: black;
}
.field {
  margin-bottom: 20px;
}
.error-message {
  color: red;
  margin-bottom: 10px;
  text-align: center;
}
.login-container {
  background-color: #ffffff;
}

.font {
  color: rgb(0, 0, 0);
  font-size: medium;
  font-family: hack, monospace;
  color: #ffffff51;
}

.grid {
  display: flex;
  flex-wrap: wrap;
}

.field {
  flex: 1 1 200px;
  margin-right: 20px;
  color: black;
}

.p-button-outlined-menu {
  color: rgba(112, 108, 108, 0);
}
.pi {
  font-family: "primeicons";
  color: rgb(0, 0, 0);
}
.company-logo {
  width: 180px;
  height: 40px;
  margin: 2px;
  background-color: rgb(224, 221, 221);
  border: 2px solid rgba(0, 0, 255, 0.5); /* blue border with transparency */
  filter: drop-shadow(0 0 5px rgba(0, 0, 255, 0.5)); /* blue blur effect */
  border-radius: 4px; /* optional: rounded corners */
}

.grid {
  padding: 10px;
}
.box-form {
  padding: 2% 1%;
  margin: 2px;
  border-radius: 10px;
  background: #ffffffed;
  height: auto;
  width: auto;
}
.box-form-windows {
  padding: 2% 1%;
  margin: 2px;
  border-radius: 10px;
  background: #ffffffed;
  min-height: 700px;
  width: auto;
  padding: 30px;
  margin: 20px;
}
.ui-dialog .ui-dialog-content {
  width: 50vw;
}

@media screen and (max-width: 768px) {
  .ui-dialog .ui-dialog-content {
    width: 80vw;
  }
  .p-icon {
    display: none;
  }
}
.nav-but {
  height: auto;
  width: 90%;
  font-size: 1rem;
  border: 1px solid black !important;
  flex-wrap: wrap;
}
.label-field {
  -webkit-text-fill-color: rgba(0, 0, 0, 0.824);
}
.topMenu-button {
  font-weight: bold; /* Change the font weight to bold */
  transform: scale(1.2); /* Scale the icon size for a bold appearance */
  color: rgb(10 34 104) !important; /* Change the color to blue (#007bff) */
}
.p-button-label {
  color: black;
}
.p-button-icon {
  color: rgb(10 34 104);
  transform: scale(1.2);
}
.p-button-icon-form {
  -webkit-text-fill-color: rgb(255, 255, 255);
  transform: scale(1.2);
  margin-right: 3px;
}
.p-button-icon-form-windows {
  -webkit-text-fill-color: rgb(255, 255, 255);
  transform: scale(1.2);
  margin-right: 3px;
}
.p-button-outlined {
  border-color: transparent;
}
.p-button-icon-form:hover {
  background-color: gray;
}
@media screen and (max-width: 768px) {
  .p-button-icon-form {
    -webkit-text-fill-color: rgb(255, 255, 255);
    transform: scale(1.2);
    margin-left: 3px;
  }
}
.button-30 {
  border-width: 0;

  width: 100%;
  background-color: #c30132ac;
  -webkit-text-fill-color: white;
}
.button-30:hover {
  box-shadow: rgba(45, 35, 66, 0.4) 0 4px 8px,
    rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #d6d6e7 0 -4px 0 inset;
  transform: translateY(-2px);
}

.m_header .m-logo {
  display: block;
  width: 15rem;
  height: 4.5rem;
  background: url("../Images/logo.svg") no-repeat center center;
}

.category-header-style {
  -webkit-text-fill-color: #000;
  background-color: rgb(236, 233, 233);
  font-size: x-large;
}
.Submit-button {
  border-width: 0;
  background-color: #47bd47;
  -webkit-text-fill-color: #ffffff;
  box-shadow: rgba(45, 35, 66, 0.4) 0 2px 4px,
    rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #2a6e2a 0 -4px 0 inset;
  width: 100%;
}
.Submit-button:active {
  border-width: 0;
  background-color: #47bd47;
  -webkit-text-fill-color: #ffffff;
  box-shadow: inset 7px 7px 7px #2a6e2a, inset -7px -7px 7px #399339;
  width: 100%;
}
.Clear-button {
  border-width: 0;
  background-color: #ff6842;
  -webkit-text-fill-color: #ffffff;

  box-shadow: rgba(45, 35, 66, 0.4) 0 2px 4px,
    rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #a83b20 0 -4px 0 inset;
  width: 100%;
}
.Clear-button:active {
  border-width: 0;
  background-color: #ff6842;
  -webkit-text-fill-color: #ffffff;
  box-shadow: inset 7px 7px 7px #ff4b1e, inset -7px -7px 7px #ff6842;
  width: 100%;
}
.Back-button {
  border-width: 0;
  background-color: #979184f0;
  -webkit-text-fill-color: #ffffff;
  box-shadow: rgba(45, 35, 66, 0.4) 0 2px 4px,
    rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #605c54f0 0 -4px 0 inset;
  width: 100%;
}
.Back-button:active {
  border-width: 0;
  background-color: #979184f0;
  -webkit-text-fill-color: #ffffff;
  box-shadow: inset 7px 7px 7px #605c54f0, inset -7px -7px 7px #605c54f0;
  width: 100%;
}
.Next-button {
  border-width: 0;
  background-color: #3585e1;
  -webkit-text-fill-color: rgb(251, 248, 248);
  box-shadow: rgba(45, 35, 66, 0.4) 0 2px 4px,
    rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #235ea1 0 -4px 0 inset;
  width: 100%;
}
.Next-button:active {
  border-width: 0;
  background-color: #3585e1;
  -webkit-text-fill-color: rgb(251, 248, 248);
  box-shadow: inset 7px 7px 7px #235ea1, inset -7px -7px 7px #235ea1;
  width: 100%;
}
.Done-button {
  border-width: 0;
  background-color: #025add;
  -webkit-text-fill-color: rgb(251, 248, 248);
  box-shadow: rgba(45, 35, 66, 0.4) 0 2px 4px,
    rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #02409e 0 -4px 0 inset;
  width: 100%;
}
.Done-button:active {
  border-width: 0;
  background-color: #025add;
  -webkit-text-fill-color: rgb(251, 248, 248);
  box-shadow: inset 7px 7px 7px #02409e, inset -7px -7px 7px #02409e;
  width: 100%;
}
.Edit-button {
  border-width: 0;
  background-color: #ffbc2c;
  -webkit-text-fill-color: #ffffff;
}
.Verfication-button {
  border-width: 0;
  background-color: #2cff2c;
  -webkit-text-fill-color: #989696;
}
.Verfication-button:active {
  border-width: 0;
  background-color: #0b9e0b;
  -webkit-text-fill-color: #989696;
  box-shadow: inset 7px 7px 7px #0b9e0b, inset -7px -7px 7px #0b9e0b;
}
.Invalid-button {
  border-width: 0;
  background-color: #ff4c2c;
  -webkit-text-fill-color: #ffffff;
}
.Invalid-button:active {
  border-width: 0;
  background-color: #ca2a0e;
  -webkit-text-fill-color: #ffffff;
  box-shadow: inset 7px 7px 7px #ca2a0e, inset -7px -7px 7px #ca2a0e;
}
.Evidance-button {
  border-width: 0;
  background-color: #2c5dff;
  -webkit-text-fill-color: #ffffff;
  line-height: 2;
  width: 100%;
}
.Evidance-button:active {
  border-width: 0;
  background-color: #1047fc;
  -webkit-text-fill-color: rgb(251, 248, 248);
  box-shadow: inset 7px 7px 7px #02409e, inset -7px -7px 7px #02409e;
}
.Excel-button {
  border-width: 0;
  background-color: #08682d;
  -webkit-text-fill-color: #ffffff;
  line-height: 2;
  width: 100%;
}
.Excel-button:active {
  border-width: 0;
  background-color: #0d9642;
  -webkit-text-fill-color: rgb(251, 248, 248);
  box-shadow: inset 7px 7px 7px #0d9642, inset -7px -7px 7px #0d9642;
}
.popup-button {
  border-width: 0;
  background-color: transparent;
}
.Edit-button:active {
  border-width: 0;
  background-color: #ffea00;
  -webkit-text-fill-color: #ffffff;
}
.Trash-button {
  border-width: 0;
  background-color: #da4444;
  -webkit-text-fill-color: #ffffff;
}
.Trash-button:active {
  border-width: 0;
  background-color: #ff2020;
  -webkit-text-fill-color: #ffffff;
}
.Drop-button {
  border-width: 0;
  background-color: #54e774;
  -webkit-text-fill-color: #ffffff;
  line-height: 1.5;
}
.form-textbox {
  border: 1px solid rgb(92, 92, 92) !important;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.589);
}

.form-textbox-windows {
  border: 1px solid rgb(92, 92, 92) !important;
  line-height: 0.1;
}
.form-select-windows {
  width: 100%;
  border: 1px solid rgb(32, 32, 32) !important;
  padding: 2px;
  line-height: 1.5;
}

.form-MultiSelect {
  width: 100%;
  border: 1px solid rgb(32, 32, 32) !important;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.589);

  padding: 2px;
}
.p-fileupload-buttonbar,
.p-fileupload-content {
  border-color: black;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.589);
}
.form-FileUpload {
  width: 100%;
  border: 1px solid rgb(32, 32, 32) !important;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.589);

  padding: 2px;
}

.QR-box {
  width: 400px;
  padding: 40px;
  border-radius: 10px;
  background-color: #1f658de6;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.521);
}
.QR-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 10px;
  background-image: url("../Images/Qr_bg.png");
}
.qr-para {
  -webkit-text-fill-color: white;
  -webkit-text-stroke-color: black;
  font-weight: "bold";
  font-size: "15px";
}
.qr-code-img {
  background-color: transparent;
  border: 10px solid white;
}
.p-dialog .p-dialog-header .p-dialog-title {
  font-weight: bold;
  font-size: 1.5rem;
}
.p-paginator-prev,
.p-paginator-element,
.p-link {
  color: black !important;
}

.DataTable-report-windows {
  border-color: 1px solid black;
}
.custom-datatable {
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.589);
}
/* ReportOfComplaints.css */
.custom-datatable .p-datatable-thead > tr > th,
.custom-datatable .p-paginator {
  background-color: #337ab7; /* Dark blue, a professional and modern color */
  color: #ffffff; /* White text for better contrast */
  border-color: #337ab7; /* Match border color with background */
}

.custom-datatable .p-datatable-thead > tr > th:hover,
.custom-datatable .p-paginator:hover {
  background-color: #286090; /* Darker shade of blue on hover for interactive feel */
}

.custom-datatable .p-datatable-tbody > tr > td {
  border-color: #337ab7; /* Match table cell border color with header */
}

.keyword-search-grid {
  box-shadow: inset 2px 2px 5px rgba(106, 106, 106, 0.589);
}
.report-header {
  filter: drop-shadow(2px 2px 5px rgb(135, 134, 134));
  display: inline;
}
.paginator-button {
  -webkit-text-fill-color: white;
}
.paginator-button:hover {
  -webkit-text-fill-color: rgb(0, 0, 0);
  box-shadow: 2px 2px 2px 2px rgba(106, 106, 106, 0.345);
  background-color: white;
}
.paginator-button:active {
  -webkit-text-fill-color: rgb(0, 0, 0);
  box-shadow: 2px 2px 2px 2px rgba(106, 106, 106, 0.345);
  background-color: rgb(129, 129, 129);
}
.para-style {
  font-size: large;
  font-family: "Times New Roman", Times, serif;
  text-align: justify;
  filter: drop-shadow(5px 5px 5px rgb(0, 0, 0));
  -webkit-text-fill-color: rgb(255, 255, 255);
  -webkit-text-stroke-color: rgb(0, 0, 0);
}
.Footer-support-page {
  height: fit-content;
  width: 100%;
  background-color: #ffffff;
  box-shadow: 2px 2px 5px 2px rgba(106, 106, 106, 0.589);
}
.img-support {
  box-shadow: inset 2px 2px 5px 2px rgba(106, 106, 106, 0.589);
  height: "100%";
  width: "100%";
  padding: "20px";
  background-color: white;
  background-image: url("../Images/bgsupportimg.png");
  background-size: cover;
}
.responsive-img {
  max-width: 100%;
  height: auto;
}
.render-clear-button-custom-datatable {
  border-width: 0;
  background-color: #ffffff;
  box-shadow: 2px 2px 2px 2px rgba(106, 106, 106, 0.345);
}
.render-clear-button-custom-datatable:active {
  border-width: 0;
  background-color: #ffffff;
  box-shadow: inset 7px 7px 7px #ffffff, inset -7px -7px 7px #ffffff;
}
.Print {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-size: cover;
  background-position: center; /* Adjust as needed */
  background-repeat: no-repeat;
  /* background-size: 600% 600%;
  animation: waveAnimation 9s ease infinite; */
  border-radius: 10px;
}
.print-form {
  width: 400px;
  padding: 40px;
  border-radius: 10px;
  background-color: #1f658de6;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

.print-button:hover {
  border-width: 0;
  background-color: #fffffff0;
  -webkit-text-fill-color: #000000;
}
.support-nav-button {
  border: 2px solid black;
  -webkit-text-fill-color: white;
  background-color: rgba(22, 100, 224, 0.573);
  line-height: 2;
}
.support-nav-button:hover {
  border: 2px solid black;
  -webkit-text-fill-color: rgb(0, 0, 0);
  background-color: rgba(255, 255, 255, 0.573);
  line-height: 3;
}
.p-float-label input {
  width: 100%;
  padding: 12px;
  border: 1px solid #dddddd;
  border-radius: 5px;
  border: 1px solid black;
  transition: border-color 0.3s ease;
}
.FileUploadButton {
  background: red;
}

.bg-test {
  background-color: #89888879;
  padding: 10px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  border: 1px solid black;
}

.bg-test > div {
  padding: 10px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  margin: 10px;
}

.bg-test > div:hover {
  transform: translateY(-5px);
}

.bg-test h4 {
  margin: 0 0 10px;
  font-size: 20px;
  font-weight: bold;
  color: #333333;
}

.bg-test p {
  margin: 0;
  font-size: 16px;
  color: #666666;
}
.bgtest-col {
  flex: 0 0 auto;
  padding: 0.5rem;
  width: 18%;
  background-color: rgb(239, 240, 240) !important;
  /* box-shadow: inset 5px 5px 5px  rgba(0, 0, 0, 0.432) !important; */
}

.custom-datatable .row-red {
  font-weight: bolder;
  color: rgb(0, 0, 0) !important; /* Optional: to ensure text is readable */
}

.custom-datatable .row-white {
  background-color: white;
  color: rgb(65, 65, 65); /* Optional: to ensure text is readable */
}
.row-style-eye-slash {
  font-style: italic;
  font-weight: bold;
  font-size: medium;
}
.row-style-eye {
  background-color: #e0e0e0 !important;
}
.header-style-column
{
  font-size: larger !important;
}

/* Approval Dashboard */

.bg-Approval {
  background-color: #28a74554; /* Change this to the desired background color */
  padding: 10px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  border: 1px solid #28a745; /* Change this to the desired border color */
}

.bg-Approval > div {
  padding: 10px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  margin: 10px;
}

.bg-Approval > div:hover {
  transform: translateY(-5px);
}

.bg-Approval h4 {
  margin: 0 0 10px;
  font-size: 20px;
  font-weight: bold;
  color: #333333;
}

.bg-Approval p {
  margin: 0;
  font-size: 16px;
  color: #666666;
}

/* Reject Dashboard */

.bg-Reject {
  background-color: #dc354554; /* Change this to the desired background color */
  padding: 10px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  border: 1px solid #dc3545; /* Change this to the desired border color */
}

.bg-Reject > div {
  padding: 10px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  margin: 10px;
}

.bg-Reject > div:hover {
  transform: translateY(-5px);
}

.bg-Reject h4 {
  margin: 0 0 10px;
  font-size: 20px;
  font-weight: bold;
  color: #333333;
}

.bg-Reject p {
  margin: 0;
  font-size: 16px;
  color: #666666;
}

/* Not Process Dashboard */

.bg-not-process {
  background-color: #ffc10754; /* Change this to the desired background color */
  padding: 10px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  border: 1px solid #ffc107; /* Change this to the desired border color */
}

.bg-not-process > div {
  padding: 10px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  margin: 10px;
}

.bg-not-process > div:hover {
  transform: translateY(-5px);
}

.bg-not-process h4 {
  margin: 0 0 10px;
  font-size: 20px;
  font-weight: bold;
  color: #333333;
}

.bg-not-process p {
  margin: 0;
  font-size: 16px;
  color: #666666;
}
